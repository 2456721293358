import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Footer from '../../components/Footer';
import * as CustomerInteractionAction from '../../CustomerInteractionActions';
import { isEmpty,cloneDeep } from 'lodash'
import UpdateAddress from '../UpdateAddress/index'
import NotFound from '../../components/FailedMessage';
import PufExpire from "../../components/pufExpire";
import SuccessMessage from '../../components/SuccessMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import './style.css';
import CustomScreen from '../CustomScreen/index';
import AlertModal from '../../components/AlertModal';
import MessageSnackBar from '../../components/MessageSnackBar';
import {formServices} from './FormServices';
import FormAttributes from './FormAttributes';
import TableLoader from "../../components/TableLoader";
import CONSTANTS from '../../Utils/Constants';
import OrderTracking from "../OrderTracking";
import SocialMedia from "../../components/SocialMedia";
import MonkeySurveyForm from "../../containers/ProcessUpdateForm/MonkeySurveyForm";
import {RightArrow, LikeIcon, DisLikeIcon} from "../../containers/ProcessUpdateForm/Icons";
import Grid from '@material-ui/core/Grid';
import SurveyMonkeyFormAttributes from './SurveyMonkeyFormAttributes';
import { Popover, Radio, Space } from 'antd';
import HelpSection from '../../components/HelpSection';
const ColorButton = withStyles(theme => ({
    root: {
      width: "100%",
      height: 50
    },
  }))(Button);

const CALENDER_TYPE_ATTRIBUTE = 3;

class ProcessUpdateForm extends React.Component {
      isFormSet = false;
      index;
      location;
      circleCenter;
      radius;
      dynamicSlotDate;
      dynamicSlots;
      isDynamicSlot = false;
      errorMessage=""
      isPayment = false;
      productAmount;
      isDisabled =false;
      radioType=0;
      runBeforeValidationCount = 0;
    constructor(props) {
        super(props);
        this.state = { 
          height: 0,
          form:{},
          updateAddress:false,
          isUpdating:false,
          updatedForm:false,
          alreadyUpdated:false,
          customScreen:false,
          slotUpdate:'',
          OrderTracking: false,
          showCalender: false,
          showNeedHelpDialog :false,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateAttributeValues = this.updateAttributeValues.bind(this);
        this.checkIfLatLng = this.checkIfLatLng.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.saveFormData =this.saveFormData.bind(this);
        this.getDynamicSlots = this.getDynamicSlots.bind(this);
        this.setDynamicSlots = this.setDynamicSlots.bind(this);
        this.fontType = this.props.trackingDetails.newLayout?this.props.trackingDetails.customerInteractionThemeSettingsDTO?.newCiData?.defaultSettings?.typeface?.fontType:"Roboto";
    }
       
    saveAddress =(form ,addressFormDetailsMap)=>{
        //update here....
        const details = this.props.trackingDetails;
        details.processUpdateForm = JSON.parse(details.processUpdateForm);
        details.processUpdateForm.formList[this.props.formIndex] = form;
        details.processUpdateForm = JSON.stringify(details.processUpdateForm);
        this.props.actions.updateTrackingDetails(details);
        this.setState({updateAddress:false, form , addressFormDetailsMap});
    }


      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
    
      componentDidMount() {
        console.log("url props",this.props.url);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({ height: window.innerHeight - document.getElementById('top-area').offsetHeight - document.getElementById('top-area').offsetHeight });
        console.log("url props----1",this.props.url);
        if(this.props.url && !isEmpty(this.props.url)){
          window.history.pushState(null, null, window.location.pathname+"?"+this.props.url);   // on back button from browser/mobile it will set current url
          window.addEventListener('popstate', this.onBackButtonEvent);
        }
        document.querySelector('body').style.overflow = "auto";     // allow scrolling of page.
        window.scrollTo(0, 0);      // to set scroll bar to top initially
      }

      onBackButtonEvent = (e) => {
        e.preventDefault();
        this.props.backScreen();
      }
    
      updateWindowDimensions() {
        this.setState({ height: window.innerHeight - document.getElementById('top-area').offsetHeight - document.getElementById('top-area').offsetHeight });
      }

      handleClick = ()=> event =>{
         this.setState({'updateAddress':false})
      }
   
       returnToMain =()=> event =>{
         this.setState({'updateAddress':false})
       }
       showViewDetailButton = () =>{
        if(this.props.trackingDetails && this.props.trackingDetails.layoutScreen){
          if(this.props.trackingDetails.layoutScreen.childMapping && this.props.trackingDetails.layoutScreen.childMapping.length>0){
            for(let i=0;i<this.props.trackingDetails.layoutScreen.childMapping.length;i++){
              if(!isEmpty(this.props.trackingDetails.layoutScreen.childMapping[i].value))
              return true;
            }
          }
          
          if(this.props.trackingDetails.layoutScreen.attributeList && this.props.trackingDetails.layoutScreen.attributeList.length>0){
            for(let i=0;i<this.props.trackingDetails.layoutScreen.attributeList.length;i++){
              if(!isEmpty(this.props.trackingDetails.layoutScreen.attributeList[i].value))
              return true;
            }
          }
          if(this.props.trackingDetails.layoutScreen.orderAttributeList && this.props.trackingDetails.layoutScreen.orderAttributeList.length>0){
            for(let i=0;i<this.props.trackingDetails.layoutScreen.orderAttributeList.length;i++){
              if(!isEmpty(this.props.trackingDetails.layoutScreen.orderAttributeList[i].value))
              return true;
            }
          }
          return false;
        }
      return false;
      }
      updateAttributeValues =(index,key,value)=>{
           if(key){
             let form = this.state.form;
             form.processInteractionKeysDtos[index]["value"] = value;
             this.setState({'form':form});
            
          }  
      }

      checkIfLatLng =(attribute,index)=>event=>{
         if(attribute && attribute.typeId==52){
                this.index = index;
                if(!isEmpty(attribute.value)){
                    this.location = attribute.value;
                }
                this.setState({'updateAddress':true})
         }

      }

      saveOnlyFormData =async()=>{
        // this.isSavedData = true;
        this.setState({'isUpdating':true});
        let form = cloneDeep(this.state.form);
        var processInteractionKeysDtos= form.processInteractionKeysDtos;

        for (let dto of processInteractionKeysDtos) {
            if (dto.addressPart || 
                (!isEmpty(dto.mappingWith) && dto.mappingWith != "[]") ||
                this.state.formDetailsMap[dto.key].hide) {
                  continue;
            }

            if(dto.typeId==52 && !isEmpty(this.state.form.locationValue)){
              dto.value = this.state.form.locationValue;
            }
            else if (dto.typeId==8){
              dto.value = JSON.stringify(this.state.formDetailsMap[dto.key].value)
            }
            else if (dto.typeId==3 || dto.typeId==4 || dto.typeId==95){
              continue;
            }
            else{
              dto.value = this.state.formDetailsMap[dto.key].value;
            }
      }
     
       let res = await this.props.actions.updateFormData(processInteractionKeysDtos,this.props.trackingDetails.masterCode,this.props.trackingDetails.referenceNumber,this.props.url,form.title,null,this.dynamicSlotDate,this.dynamicSlots,this.props.config3PLDataMap,this.props.trackingDetails,this.state.form,this.props.dynamicSlotList,
                                                           this.props.processUrl,this.isPayment,this.productAmount,this.props.trackingDetails.type, this.props.selectedSlotFenceId,this.props.isReturn);     
       this.setState({'isUpdating':false});
  }


      saveFormData =async()=>{
          // this.isSavedData = true;
          this.setState({'isUpdating':true});
          let form = cloneDeep(this.state.form);
          var processInteractionKeysDtos= form.processInteractionKeysDtos;

          for (let dto of processInteractionKeysDtos) {
              if (dto.addressPart || 
                  (!isEmpty(dto.mappingWith) && dto.mappingWith != "[]") ||
                  this.state.formDetailsMap[dto.key].hide) {
                    continue;
              }

              if(dto.typeId==52 && !isEmpty(this.state.form.locationValue)){
                dto.value = this.state.form.locationValue;
              }
              else if (dto.typeId==8){
                dto.value = JSON.stringify(this.state.formDetailsMap[dto.key].value)
              }
              else if (dto.typeId==3 || dto.typeId==4 || dto.typeId==95){
                continue;
              }
              else{
                dto.value = this.state.formDetailsMap[dto.key].value;
              }
        }
       
         let res = await this.props.actions.updateFormData(processInteractionKeysDtos,this.props.trackingDetails.masterCode,this.props.trackingDetails.referenceNumber,this.props.url,form.title,null,this.dynamicSlotDate,this.dynamicSlots,this.props.config3PLDataMap,this.props.trackingDetails,this.state.form,this.props.dynamicSlotList,
                                                             this.props.processUrl,this.isPayment,this.productAmount,this.props.trackingDetails.type, this.props.selectedSlotFenceId,this.props.isReturn);     
         if(res=="200"){
             this.errorMessage ="";
             this.setState({'form':form,'isUpdating':false,updatedForm:true})
          } else {
            if(res && res.includes("[CI]")){
               this.errorMessage = res.substring(0,res.indexOf("[CI]"));
            } else {
              this.errorMessage = containerConstants.formatString(containerConstants.OopsSomethingWentWrongPleaseTryAfterSomeTime);
            }
            this.setState({'isUpdating':false});
          }
       }

      
    getDynamicSlots=(date,coordinatorSlotObject)=>{
        this.dynamicSlots = '';
        this.dynamicSlotDate = date;
        this.props.actions.getDynamicSlots(date,cloneDeep(this.state.form), this.props.config3PLDataMap, this.props.trackingDetails,this.props.processUrl,coordinatorSlotObject);
    }

    getAvailableDatesFromSlotBookingCoordinator = (dynamicSlotDates) => {
      this.props.actions.getAvailableDatesFromSlotBookingCoordinator(this.props.trackingDetails,this.state.form, this.props.config3PLDataMap,this.props.processUrl,dynamicSlotDates);

    }

    setDynamicSlots=(slot)=>{
         this.dynamicSlots = slot;
         this.setState({slotUpdate:slot})
    }

    checkCalendarType = (attribute) => {
      if(attribute && attribute.typeId == CALENDER_TYPE_ATTRIBUTE) {
        const calendars = ['US', 'Arabic', 'Hebrew', 'ISO 8601'];
        if(this.state.form && 
          this.state.form.calendarType &&
          calendars.indexOf(this.state.form.calendarType) < 0 &&
          isEmpty(this.props.holidayMaster) && this.props.holidayMasterApiCallFlag==0) {
            this.props.actions.fetchHolidayMaster(this.props.url, this.state.form.calendarType);
            this.props.actions.incrementHolidayMasterApiCount(this.props.holidayMasterApiCallFlag+1);
          }
      }
    }

  updateValue=(key, value)=> {
    var formDetailsMap=cloneDeep(this.state.formDetailsMap);
    formDetailsMap[key].value = value;
    this.setState({formDetailsMap})

  } 

  runValidation = (key, executionTime) => {
    var formDetailsMap=cloneDeep(this.state.formDetailsMap);
    formServices.runValidation(formDetailsMap,key,executionTime);
    this.setState({formDetailsMap})

  }
  updateValueSurveyFormWithValidation =(key,value)=>{
    var formDetailsMap=cloneDeep(this.state.formDetailsMap);
    formDetailsMap[key].value = value;
    formServices.runValidation(formDetailsMap,key,"route");
    this.setState({formDetailsMap})
  }

  runBeforeValidationAndSetFormDetailsMap = (formDetailsMap, addressFormDetailsMap)=>{
    formServices.runAllValidation(formDetailsMap,"before");
    this.setState({formDetailsMap,addressFormDetailsMap})
  }
  isSumbitEnabled= () => {
    if(!this.state.formDetailsMap) return false;

    if(this.allRequiredFieldsAreFilled()==false){
      this.isDisabled= true;
      return false;
    }

    return this.isAddressSubmitEnabled();
  }

  isAddressSubmitEnabled = () => {
    if(!this.state.addressFormDetailsMap) return true;

    for (let key in this.state.addressFormDetailsMap) {
      var detailObj = this.state.addressFormDetailsMap[key];
      if (detailObj.required && !detailObj.hide && isEmpty(detailObj.value)) {

        return false;
      }
    }

    return true;
  }
  updateLocationValue = (form) => {
    var latLng = ''
    if (form && !isEmpty(form.processInteractionKeysDtos)) {
      for (let dto of form.processInteractionKeysDtos) {
        if (dto.typeId == 52) {
          latLng = dto.value
        }
      }
    }
    form.locationValue = latLng;
  }


  renderFormAttributes = () => {
    if(this.props.availableDatesFetchingLoader){
      return <div>
         <div style={{position: 'absolute', top: '45%', transform: 'translate(-50%, -50%)', left: '50%'}}>Fetching Available Dates</div> 
         <TableLoader />
        </div>
    }
    let attributeList = [];
    if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
      let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
      var form=forms.formList[this.props.formIndex];
      if(!form){
        return attributeList;
      }
      var formDetailsMap = formServices.getFormDetailsMap(form.processInteractionKeysDtos, false);
      var addressFormDetailsMap = formServices.getFormDetailsMap(form.processInteractionKeysDtos, true);
      if(this.runBeforeValidationCount == 0){
        this.runBeforeValidationAndSetFormDetailsMap(formDetailsMap, addressFormDetailsMap)
        this.runBeforeValidationCount = 1;
        }
      // check here index and forms 
      if (!this.isFormSet) {
        this.isFormSet = true;
        this.updateLocationValue(form);
        this.setState({ form });

      } else if (forms && forms.formList && forms.formList.length == 1 && forms.formList[0].alreadyUpdated) {
        this.setState({ alreadyUpdated: true })
      }
      // check if expire or not
      if (this.state.form && !isEmpty(this.state.form.processInteractionKeysDtos)) {

        (this.state.form.processInteractionKeysDtos).map((attribute, index) => {
          if (!(attribute.addressPart || (!isEmpty(attribute.mappingWith) && attribute.mappingWith != "[]"))) {
            this.checkCalendarType(attribute);
            attributeList.push(
              <FormAttributes
                key={attribute.key}
                updateValue={this.updateValue}
                runValidation={this.runValidation}
                formDetailsMap={this.state.formDetailsMap}
                attribute={attribute}
                index={index}
                updateAttributeValue={this.updateAttributeValues}
                checkLatLng={this.checkIfLatLng}
                latLngValue={(this.state.form && this.state.form.addressKeys) ? this.state.form.addressKeys : ''}
                fontColor={this.state.form.fontColor}
                fontType={this.state.form.fontType}
                fontSize={this.state.form.fontSize}
                calendarType={this.state.form.calendarType}
                getDynamicSlots={attribute.typeId == 95 ? this.getDynamicSlots : ''}
                slotList={attribute.typeId == 95 ? this.props.dynamicSlotList : ''}
                alertMessage={attribute.typeId == 95 ? this.props.alertMessage : ''}
                setDynamicSlots={attribute.typeId == 95 ? this.setDynamicSlots : ''}
                language={this.props.trackingDetails.language}
                fontFamily={this.props.fontFamily}
                holidayMaster={(this.state.form && this.state.form.holidayMaster) ? this.state.form.holidayMaster : {}}
                isAddressSubmitEnabled={attribute.typeId && attribute.typeId == 52 ? this.isAddressSubmitEnabled() : true}
                form={this.state.form}
                config3PLDataMap={this.props.config3PLDataMap}
                trackingDetails={this.props.trackingDetails}
                processUrl={this.props.processUrl}
                availableDatesFromCoordinator={this.props.availableDatesFromCoordinator}
                fullDaySlotsEnabled={this.props.fullDaySlotsEnabled}
              />);
            if (attribute.typeId == 95 && !isEmpty(attribute.value) && !isEmpty(attribute.minRange) && !isEmpty(attribute.maxRange) && !this.isDynamicSlot) {
              this.isDynamicSlot = true;
              this.getAvailableDatesFromSlotBookingCoordinator(attribute.dynamicSlotDates);
            }
            if (attribute.typeId == 52 && !isEmpty(attribute.value)) {
              this.circleCenter = (attribute.value).split(',');
              if (!isEmpty(attribute.minRange)) {
                this.radius = attribute.minRange;
              }
            }
          }
        })
      }
    }
    return attributeList;
  }


      checkIfPaymentAllowed=()=>{
        if(!this.isPayment && this.props.trackingDetails && this.props.trackingDetails.customerInteractionPaymentInfoDTO && this.props.trackingDetails.customerInteractionPaymentInfoDTO.payment 
          && !isEmpty(this.state.form) && this.state.form.payment){
            console.log("payment true");
           this.isPayment = true; 
           this.productAmount = this.props.trackingDetails.customerInteractionPaymentInfoDTO.productAmount;
         this.props.actions.getPaymentOrderDetails(this.props.url,null)
       }
       if(this.props.paymentStatus){
          this.errorMessage=this.props.paymentStatus =="FAILED"?"Your last payment was not successful.Please try again":
          this.props.paymentStatus =="INITIATED"?!isEmpty(this.props.alertMessage)?this.props.alertMessage:"Seems like your last payment was incomplete. If you have already paid, please check after sometime.":'';
       }
      }
      
      renderSocialMedia = () => {
        return (
          <div className='ci-puf-social'>
            <SocialMedia 
              theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
              layoutScreen={this.props.trackingDetails.layoutScreen}
              secondaryBgColor={this.props.secondaryBgColor}
              language={this.props.trackingDetails?.language || "en"}
              fontFamily={this.fontType}
            />
          </div>
        );
      };
      openCalenderNewUi=()=>{
        this.setState({
          showCalender: !this.state.showCalender
      }); 
      }
      closeCalenderNewUi = () => {
        this.setState({
            showCalender: false
        });
    }
    allRequiredFieldsAreFilled = ()=>{
      if(this.state.formDetailsMap){
        for (let key in this.state.formDetailsMap) {
        var detailObj = this.state.formDetailsMap[key];
        if (detailObj.required && !detailObj.hide) {
          var invaid=false;
          switch(detailObj.typeId){
            case 52:
              if(isEmpty(this.state.form.locationValue)){
                invaid=true;
              }
              break;
            case 95:
              if(!isEmpty(this.props.config3PLDataMap) && this.props.config3PLDataMap[this.state.form.title] && this.props.config3PLDataMap[this.state.form.title].fullDaySlotsEnabled){
                if(!this.dynamicSlotDate){
                  invaid=true;
                }
              }
              else
              if(isEmpty(this.dynamicSlots) || isEmpty(this.props.dynamicSlotList)  ||  this.props.alertMessage =="No Available Slot Found"){
                invaid=true;
              }
              break;
            default:
              if(!detailObj.value){
                invaid=true;
              }
              else if( (typeof detailObj.value !="number" && typeof detailObj.value != "boolean") && isEmpty(detailObj.value) ){
                invaid=true;
              }
              break;
          }
          this.isDisabled= invaid;
          if(invaid){
            return false;
          }
        }
      }
      return true;
    }
    }
      renderSurveyAttributes = () =>{
        let ctaFontColor=this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaFontColor;
        let secondaryBgColor=this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.secondaryBgColor;
        let ctaColorWhite= this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaColorWhite;
        let primaryBgColor = '';
        if(this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor){
          primaryBgColor = this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor;
        }
        if(this.props.availableDatesFetchingLoader){
          return <div>
             <div style={{position: 'absolute', top: '45%', transform: 'translate(-50%, -50%)', left: '50%'}}>Fetching Available Dates</div> 
             <TableLoader />
            </div>
        }
        let attributeList = [];
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
          let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
          var form=forms.formList[this.props.formIndex];
          if(!form){
            return attributeList;
          }
          var formDetailsMap = formServices.getFormDetailsMap(form.processInteractionKeysDtos, false);
          var addressFormDetailsMap = formServices.getFormDetailsMap(form.processInteractionKeysDtos, true);
          if(this.runBeforeValidationCount == 0){
          this.runBeforeValidationAndSetFormDetailsMap(formDetailsMap, addressFormDetailsMap)
          this.runBeforeValidationCount = 1;
          }
          // check here index and forms 
          if (!this.isFormSet) {
            this.isFormSet = true;
            this.updateLocationValue(form);
            this.setState({ form });
    
          } else if (forms && forms.formList && forms.formList.length == 1 && forms.formList[0].alreadyUpdated) {
            this.setState({ alreadyUpdated: true })
          }
          // check if expire or not
          if (this.state.form && !isEmpty(this.state.form.processInteractionKeysDtos)) {
    
            (this.state.form.processInteractionKeysDtos).map((attribute, index) => {
              if (!(attribute.addressPart || (!isEmpty(attribute.mappingWith) && attribute.mappingWith != "[]"))) {
                this.checkCalendarType(attribute);
                attributeList.push(
                  <SurveyMonkeyFormAttributes
                    openCalenderNewUi={this.openCalenderNewUi}
                    closeCalenderNewUi={this.closeCalenderNewUi}
                    showCalender={this.state.showCalender}
                    key={attribute.key}
                    updateValue={this.updateValue}
                    runValidation={this.runValidation}
                    updateValueSurveyFormWithValidation={this.updateValueSurveyFormWithValidation}
                    formDetailsMap={this.state.formDetailsMap}
                    attribute={attribute}
                    index={index}
                    updateAttributeValue={this.updateAttributeValues}
                    checkLatLng={this.checkIfLatLng}
                    latLngValue={(this.state.form && this.state.form.addressKeys) ? this.state.form.addressKeys : ''}
                    fontColor={this.state.form.fontColor}
                    fontType={this.state.form.fontType}
                    fontSize={this.state.form.fontSize}
                    calendarType={this.state.form.calendarType}
                    getDynamicSlots={attribute.typeId == 95 ? this.getDynamicSlots : ''}
                    slotList={attribute.typeId == 95 ? this.props.dynamicSlotList : ''}
                    alertMessage={attribute.typeId == 95 ? this.props.alertMessage : ''}
                    setDynamicSlots={attribute.typeId == 95 ? this.setDynamicSlots : ''}
                    language={this.props.trackingDetails.language}
                    saveOnlyFormData={this.saveOnlyFormData}
                    fontFamily={this.props.fontFamily}
                    holidayMaster={(this.state.form && this.state.form.holidayMaster) ? this.state.form.holidayMaster : {}}
                    isAddressSubmitEnabled={attribute.typeId && attribute.typeId == 52 ? this.isAddressSubmitEnabled() : true}
                    form={this.state.form}
                    config3PLDataMap={this.props.config3PLDataMap}
                    trackingDetails={this.props.trackingDetails}
                    processUrl={this.props.processUrl}
                    availableDatesFromCoordinator={this.props.availableDatesFromCoordinator}
                    fullDaySlotsEnabled={this.props.fullDaySlotsEnabled}
                  />);
                if (attribute.typeId == 95 && !isEmpty(attribute.value) && !isEmpty(attribute.minRange) && !isEmpty(attribute.maxRange) && !this.isDynamicSlot) {
                  this.isDynamicSlot = true;
                  this.getAvailableDatesFromSlotBookingCoordinator(attribute.dynamicSlotDates);
                }
                if (attribute.typeId == 52 && !isEmpty(attribute.value)) {
                  this.circleCenter = (attribute.value).split(',');
                  if (!isEmpty(attribute.minRange)) {
                    this.radius = attribute.minRange;
                  }
                }
              }
            })
          }
        }
      
      
      return (
        <div className='monkey-survey-form-fluid socialMediaFooter'>
            <div className='monkey-survey-form-container bottom'>
            {this.state.updatedForm?<MessageSnackBar
                message={this.state.form.successMessage}
                />:null}
              {attributeList}
                  {!isEmpty(this.errorMessage) ? <p className="error-message">{this.errorMessage}</p> : null}
                  {this.state.isUpdating ?
                    <div clasName="text-center m10">
                      <Fade
                        in={this.state.loader}
                        style={{
                          transitionDelay: (this.state.loader) ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >saveForm
                        <CircularProgress />
                      </Fade>
                    </div> : null}
                    <div className='monkey-survey-form-group-button'>
                      <Popover 
                      content={!this.allRequiredFieldsAreFilled()?
                        <span style={{color:"red"}}>
                        {containerConstants.formatString(containerConstants.PleaseAnswerAllMandatoryQuestions)} 
                        </span>
                        :null}
                      showArrow={false} 
                      trigger="click||hover"
                      >
                        <button variant="contained" color="primary" onClick={this.saveFormData} disabled={!this.isSumbitEnabled() || this.state.isUpdating}
                          style={{  background: this.isDisabled ?" ":( ctaColorWhite?"white": (secondaryBgColor||primaryBgColor)) }}
                          >
                          <span id="saveButtonDesktop" className="saveButton"
                          title={this.state.form.title?this.state.form.title:"saveButtonDesktop"} style={{
                            color:  this.isDisabled ? '' : ctaFontColor,
                            fontFamily: this.props.fontFamily ? this.props.fontFamily : this.state.form.fontType,
                            fontSize: this.props.fontSize ? this.props.fontSize : this.state.form.fontSize
                          }}>
                            {this.state.isUpdating ? containerConstants.formatString(containerConstants.Updating) : !isEmpty(this.state.form.saveButtonTitle) ? this.state.form.saveButtonTitle : containerConstants.formatString(containerConstants.Update)}</span>
                        </button>
                      </Popover>
                    </div>
                
            </div>
        </div>
      )
      }
      renderDesktopViewProcessForm = (ctaFontColor,ctaColorWhite,secondaryBgColor) =>{
        let primaryBgColor = '';
        if(this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor){
          primaryBgColor = this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor;
        }
        return(
          <>
          {this.state.form.newUi?
          this.renderSurveyAttributes()
          :
          
          <div className="ci-background-grey " >
            <div className="content overflow-y orderTrackingScreen desktopView  ci-desktopView">
              <div className='ci-processform' style={{fontFamily:`${this.fontType||"Roboto"},sans-serif`}}>
                {this.state.updatedForm?<MessageSnackBar
                message={this.state.form.successMessage}
                />:null}
                <div className="bg-white p10 content">
                    <p className="bold fs18 pt15 mb10" style={{color:this.state.form.fontColor,fontFamily:this.state.form.fontType,fontSize:this.state.form.fontSize}}>{this.state.form.introText}</p>
                    {this.renderFormAttributes()}
                </div>
                <Footer id="bottom-area" >
                  {!isEmpty(this.errorMessage) ? <p className="error-message">{this.errorMessage}</p> : null}
                  {this.state.isUpdating ?
                    <div clasName="text-center m10">
                      <Fade
                        in={this.state.loader}
                        style={{
                          transitionDelay: (this.state.loader) ? '800ms' : '0ms',
                        }}
                        unmountOnExit
                      >saveForm
                        <CircularProgress />
                      </Fade>
                    </div> : null}
                  <span style={{ cursor: 'not-allowed' }}>
                    <ColorButton variant="contained" color="primary" onClick={this.saveFormData} disabled={!this.isSumbitEnabled() || this.state.isUpdating}
                      style={{ background: this.isDisabled ?" ":( ctaColorWhite?"white": (secondaryBgColor||primaryBgColor))  }}>
                      <span id="saveButtonDesktop" title={this.state.form.title?this.state.form.title:"saveButtonDesktop"} style={{
                        color:  this.isDisabled ? '' : ctaFontColor,
                        fontFamily:`${this.fontType||"Roboto"},sans-serif`,
                        fontSize: this.props.fontSize ? this.props.fontSize : this.state.form.fontSize
                      }}>
                        {this.state.isUpdating ? containerConstants.formatString(containerConstants.Updating) : !isEmpty(this.state.form.saveButtonTitle) ? this.state.form.saveButtonTitle : containerConstants.formatString(containerConstants.Update)}</span>
                    </ColorButton>
                  </span>
                </Footer>
              </div>
            </div>
          </div>
          }
          </>
        )
      }

      getFormView=()=>{
       
        let primaryBgColor = '';
        if(this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO && this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor){
          primaryBgColor = this.props.trackingDetails.customerInteractionThemeSettingsDTO.primaryBgColor;
        }
        let ctaFontColor=this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaFontColor;
        let secondaryBgColor=this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.secondaryBgColor;
        let ctaColorWhite= this.props?.trackingDetails?.customerInteractionThemeSettingsDTO?.ctaColorWhite;
        this.getSuccessView();
        this.checkIfPaymentAllowed();
        return (
        <div>
            {(this.props.trackingDetails.isMobileType || (this.props.isMobileType)) ? 
            this.state.form.newUi?
              this.renderSurveyAttributes()
              :
            <div className='ci-processform' style={{fontFamily:`${this.fontType||"Roboto"},sans-serif`}}>
              {this.state.updatedForm?<MessageSnackBar
              message={this.state.form.successMessage}
              />:null}
              <div className="bg-white p10 mt5 content">
                  <p className="bold fs18 pt15 mb10" style={{color:this.state.form.fontColor,fontFamily:`${this.fontType||"Roboto"},sans-serif`,fontSize:this.state.form.fontSize}}>{this.state.form.introText}</p>
                  {this.renderFormAttributes()}
              </div>
              <Footer id="bottom-area" >
                {!isEmpty(this.errorMessage) ? <p style={{fontFamily:`${this.fontType||"Roboto"},sans-serif`}} className="error-message">{this.errorMessage}</p> : null}
                {this.state.isUpdating ?
                  <div clasName="text-center m10">
                    <Fade
                      in={this.state.loader}
                      style={{
                        transitionDelay: (this.state.loader) ? '800ms' : '0ms',
                      }}
                      unmountOnExit
                    >saveForm
                      <CircularProgress />
                    </Fade>
                  </div> : null}
                <span style={{ cursor: 'not-allowed' }}>
                  <ColorButton variant="contained" color="primary" onClick={this.saveFormData} disabled={!this.isSumbitEnabled() || this.state.isUpdating}
                    style={{ background: this.isDisabled ?" ":( ctaColorWhite?"white": (secondaryBgColor||primaryBgColor)) }}>
                    <span id="saveButtonMobile" style={{
                      color: this.isDisabled ? '' : ctaFontColor,
                      fontFamily:`${this.fontType||"Roboto"},sans-serif`,
                      fontSize: this.props.fontSize ? this.props.fontSize : this.state.form.fontSize
                    }}>
                      {this.state.isUpdating ? containerConstants.formatString(containerConstants.Updating) : !isEmpty(this.state.form.saveButtonTitle) ? this.state.form.saveButtonTitle : containerConstants.formatString(containerConstants.Update)}</span>
                  </ColorButton>
                </span>
              </Footer>
            </div>
            :
            this.renderDesktopViewProcessForm(ctaFontColor,ctaColorWhite,secondaryBgColor)}
            
            {
              (!this.props.isCurbsidePickup) && 
              (this.props.trackingDetails.layoutScreen || this.props.trackingDetails.customerInteractionThemeSettingsDTO) &&
              this.renderSocialMedia()
            }
            
        </div>

        )
      }

      cancelPayment=()=>{
        console.log("cancelPayment");
        this.props.actions.updatePaymentInfo(false,this.props.url)
      }

      updatePaymentInfo=async()=>{
        await this.props.actions.updatePaymentInfo(true,this.props.url)
        this.saveFormData();
      }

      updateScreen(){
        if (this.props.backScreen) {
          if(this.props.trackingDetails.type != CONSTANTS.CURBSIDE_PICKUP)
          this.props.backScreen();
        } else {
          this.setState({updatedForm:false})
        }
      }

      getSuccessView = () => {
        if (this.state.updatedForm) {
            setTimeout(
              function() {
                this.updateScreen()
              }.bind(this),
              3000
            );
        }
      };

      showCustomScreen=()=>{
          this.setState({customScreen:!this.state.customScreen})
      }
      showOrderTracking=()=>{
        this.setState({OrderTracking:!this.state.OrderTracking})
    }
    closeHelpModal =() =>{
      this.setState({showNeedHelpDialog:!this.state.showNeedHelpDialog})
    }
    setHelpModel = () => {
      this.setState({ showNeedHelpDialog: true });
    };

    render(){
      let theme =
      this.props.trackingDetails &&
      this.props.trackingDetails.customerInteractionThemeSettingsDTO
        ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
        : undefined;
      const {primaryBgColor,primaryTextColor, fontSize, fontFamily, secondaryBgColor, secondaryTextColor }= this.props
      if(this.props.initiatedTime && this.props.initiatedTime>-1){
          return <AlertModal
                   cancel={this.cancelPayment}
                   updatePaymentInfo = {this.updatePaymentInfo}
                   time={this.props.initiatedTime}
                  />
      }
      else if(!(this.props.calledFromMainScreen && this.state.updatedForm) && ((this.state.form && this.state.form.expire)||((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm 
        && isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList))))){
        return  <PufExpire /> 
      }
      else if(this.state.updateAddress){
          return <UpdateAddress 
                  handleClick = {this.handleClick} 
                  returnToMain ={this.returnToMain()} 
                  index ={this.index} 
                  form ={cloneDeep(this.state.form)}
                  location ={this.location}
                  saveAddress ={this.saveAddress}
                  radius = {this.radius}
                  circleCenter = {this.circleCenter}
                  customScreen={this.props.trackingDetails.customScreen && !isEmpty(this.props.trackingDetails.customScreen)}
                  language={this.props.trackingDetails.language}
                  primaryBgColor={primaryBgColor}
                  primaryTextColor={primaryTextColor}
                  secondaryBgColor={secondaryBgColor}
                  secondaryTextColor={secondaryTextColor}
                  fontSize={fontSize}
                  fontFamily={fontFamily}
                  trackingDetails={this.props.trackingDetails}
                  />
        } else if(this.state.customScreen){
           return <CustomScreen
                     trackingDetails = {this.props.trackingDetails}
                    primaryBgColor={primaryBgColor? primaryBgColor: this.state.form.bgColor}
                    primaryTextColor={primaryTextColor? primaryTextColor: this.state.form.fontColor}
                    fontSize={fontSize? fontSize:this.state.form.fontSize }
                    fontFamily={fontFamily? fontFamily : this.state.form.fontType}
                    secondaryBgColor={secondaryBgColor}
                    secondaryTextColor={secondaryTextColor}
                    backScreen = {this.showCustomScreen}  
           />
        }else if(this.state.OrderTracking){
          return <div>
          <Header 
          heading ={ this.props.trackingDetails.companyName} 
          logo={this.props.trackingDetails.companyLogo} 
          back ={this.showOrderTracking} 
          backScreen = {this.showOrderTracking}
          primaryBgColor={primaryBgColor? primaryBgColor: this.state.form.bgColor}
          primaryTextColor={primaryTextColor? primaryTextColor: this.state.form.fontColor}
          fontSize={fontSize? fontSize:this.state.form.fontSize }
          fontFamily={fontFamily? fontFamily : this.state.form.fontType}
          secondaryBgColor={secondaryBgColor? secondaryBgColor: this.state.form.bgColor}
          secondaryTextColor={secondaryTextColor ? secondaryTextColor: this.state.form.fontColor}
          customBackIcon={this.props.customBackIcon}
          ctaColorWhite={theme && theme.ctaColorWhite}
          ctaFontColor={theme && theme.ctaFontColor}/>
          <OrderTracking
          track={false}
          back={this.showOrderTracking}
          subHeaderHide={true}
          backtoLayoutScreen={true}
          secondaryBgColor={this.props.secondaryBgColor}
          secondaryTextColor={this.props.secondaryTextColor}
          landingLayout = {this.props.landingLayout}
          surveyForm = {true}
          
        /></div>
       }
      let  {referenceNumber,packageDetails,awb ,trackingAttribute}=this.props.trackingDetails;
        return(
            <div className='ci-puf'
              onClick={(event)=>{
              if(!((event.target && event.target.id && event.target.id=="standard-name-calender")
              || (event.target && event.target.localName=="abbr") || (event.target && event.target.parentElement && event.target.parentElement.className && (event.target.parentElement.className=="react-calendar__month-view__days"||event.target.parentElement.className=="react-calendar__navigation")))){
                this.closeCalenderNewUi()
              }
              }}
              >
                <div id="top-area">
                    <Header 
                    isSurveyFormNeedHelp ={true}
                    setHelpModel={this.setHelpModel}
                    ctaColorWhite={theme && theme.ctaColorWhite}
                    ctaFontColor={theme && theme.ctaFontColor}
                    needHelp={true}
                    heading ={ this.props.trackingDetails.companyName} 
                    logo={this.props.trackingDetails.companyLogo} 
                    back ={this.props.back} 
                    backScreen = {this.props.backScreen} 
                    primaryBgColor={primaryBgColor? primaryBgColor: this.state.form.bgColor}
                    primaryTextColor={primaryTextColor? primaryTextColor: this.state.form.fontColor}
                    fontSize={fontSize? fontSize:this.state.form.fontSize }
                    fontFamily={fontFamily? fontFamily : this.state.form.fontType}
                    secondaryBgColor={secondaryBgColor? secondaryBgColor: this.state.form.bgColor}
                    secondaryTextColor={secondaryTextColor ? secondaryTextColor: this.state.form.fontColor}
                    customBackIcon={this.props.customBackIcon}
                    landingLayout = {this.props.landingLayout}
                    isV3 = {this.props.isCurbsidePickup && !this.props.isMobileType}

                    customScreen={this.props.trackingDetails.customScreen && !isEmpty(this.props.trackingDetails.customScreen)}/>
                    
                    {!this.props.hideSubHeader && 
                     !this.state.form.newUi?
                      <SubHeader trackingDetails={this.props.trackingDetails} 
                                secondaryBgColor={secondaryBgColor? secondaryBgColor: this.state.form.secondaryBgColor}
                                secondaryTextColor={secondaryTextColor? secondaryTextColor: this.state.form.fontColor}
                                fontSize={fontSize?fontSize : this.state.form.fontSize }
                                fontFamily={fontFamily?fontFamily :  this.state.form.fontType} 
                                customScreen={this.props.trackingDetails.customScreen && !isEmpty(this.props.trackingDetails.customScreen) && !(this.state.alreadyUpdated || (this.state.form && this.state.form.alreadyUpdated))}
                                showCustomScreen = {this.showCustomScreen}
                                landingLayout = {this.props.landingLayout}
                                showOrderTracking = {this.showOrderTracking}
                                primaryTextColor = {primaryTextColor}
                                primaryBgColor={primaryBgColor? primaryBgColor: this.state.form.bgColor}
                      />:
                      <div className='monkey-survey-form-fluid'>
                      <div className='monkey-survey-form-container top'>
                      <div className='monkey-survey-form-group-row'>
                        <div className='monkey-survey-form-share-heading'>
                            <h4>{this.state.form.introText}</h4>
                            <p>{this.state.form.description}</p>
                        </div>
                        <div className='monkey-survey-form-share-inner'>
                            <div className='monkey-survey-form-share-order-no'>
                                <label className='monkey-survey-form-label'>Order Number</label>
                                <span>{trackingAttribute ? trackingAttribute : referenceNumber} </span>
                            </div>
                            
                            {this.showViewDetailButton()?<Grid item xs={5}  className="viewdetails monkey-survey-form-view-detail-div" >
                            <button className='monkey-survey-form-view-btn' id="viewDetailsButton"
                            onClick ={this.showOrderTracking}
                            >{containerConstants.formatString(containerConstants.ViewDetails)}<RightArrow/></button>
                            </Grid>:null}
                            
                        </div>
                    </div>
                    </div>
                    </div>
                    }
                </div>
               {this.state.showNeedHelpDialog &&
                <HelpSection
                showNeedHelpDialog={true}
                setHelpSection={this.closeHelpModal}
                trackingDetails={this.props.trackingDetails}
                isSurveyFormNeedHelp ={true}
              />}
                 {
                 ((this.props.paymentStatus && this.props.paymentStatus=="SUCCESS") || this.state.alreadyUpdated || (this.state.form && this.state.form.alreadyUpdated))? <SuccessMessage message = {this.state.form.successMessage}/>:this.getFormView()}
            </div>    
        )
    }

}

function mapStateToProps(state) {
    return {
      trackingDetails: state.customerInteractionReducer.trackingDetails,
      url:state.customerInteractionReducer.url,
      dynamicSlotList:state.customerInteractionReducer.dynamicSlots,
      alertMessage:state.customerInteractionReducer.alertMessage,
      paymentStatus:state.customerInteractionReducer.paymentStatus,
      initiatedTime:state.customerInteractionReducer.initiatedTime,
      holidayMaster:state.customerInteractionReducer.holidayMasterInfo,
      config3PLDataMap:state.customerInteractionReducer.config3PLDataMap,
      processUrl:state.customerInteractionReducer.processUrl,
      availableDatesFromCoordinator:state.customerInteractionReducer.availableDatesFromCoordinator,
      fullDaySlotsEnabled:state.customerInteractionReducer.fullDaySlotsEnabled,
      availableDatesFetchingLoader: state.customerInteractionReducer.availableDatesFetchingLoader,
      selectedSlotFenceId: state.customerInteractionReducer.selectedSlotFenceId,
      holidayMasterApiCallFlag: state.customerInteractionReducer.holidayMasterApiFlag
    };
  }
  
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch)
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(ProcessUpdateForm);